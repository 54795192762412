import React from "react"
import Helmet from "react-helmet"
import Menu from "../components/menu"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <Helmet>
      <link href="https://fonts.googleapis.com/css?family=Monoton&display=swap" rel="stylesheet"/>
    </Helmet>
    <SEO title="404 Not Found"></SEO>
    <div className="error board">
      <p id="error">error</p>
      <p id="error-404">404</p>
    </div>
    <Menu active="home"></Menu>
  </>
)

export default NotFoundPage
